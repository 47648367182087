import React from "react"
import Fullpage from "../components/fullpage"
import Navbar from "../components/navbar"
import * as styles from "./sound.module.css"
import { graphql } from "gatsby"

export default function Sound({data}) {
  const links = data.allMarkdownRemark.nodes[0].frontmatter.links;

  return (
    <Fullpage>
      <Navbar />
      <div className={styles.main}>
        {links.map(link=>
          <iframe
          className={styles.video}
          width="70vw"
          height="70vh"
          src={link.url}
          title="Sugarblue"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        )}
        
        
      </div>
    </Fullpage>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: {fields: frontmatter___lastname}
      filter: {frontmatter: {lastname: {}}, fileAbsolutePath: {regex: "/sound.md/"}}
    ) {
      nodes {
        frontmatter {
          title
          links {
            titel
            url
          }
        }
        html
        id
        fileAbsolutePath
      }
    }
  }
`